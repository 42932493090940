import {Divider, IconButton,Button,Chip, Dialog, DialogTitle, DialogContent, DialogActions, List, MenuItem, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import useResponsive from "src/hooks/useResponsive";
import {styled, Theme} from "@mui/material/styles";
import { CustomAvatar } from "src/components/custom-avatar";
import { getCoresTemplate } from "src/utils/coresTemplate";
import {useSettingsContext} from "../../../../components/settings";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";
import MenuPopover from "../../../../components/menu-popover";

import BadgeStatus, {BadgeStatusValue} from "../../../../components/badge-status";

import AvatarImage from "./profile_picture.jpg"
import {PATH_DASHBOARD} from "../../../../routes/paths";
import axios from "../../../../utils/axios";
import {criarevento} from "../../../../utils/analytics ";
import Atendimento from "../../Atendimento/Atendimento";




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));
  
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

type props = {
    atendente: any;
};

function getStatusColor(status: string, theme: Theme): string {
    switch (status) {
        case 'Disponivel':
            return theme.palette.success.main; // Cor para status 'online'.
        case 'Offline':
            return theme.palette.error.main; // Cor para status 'offline'.
        // Adicione outros casos conforme necessário para outros status.
        default:
            return theme.palette.primary.main; // Cor padrão.
        }
}

function formatarTextoCaptalizar(text: string) {
    // Converte todas as letras para minúsculas e divide a string em palavras
    const words = text.toLowerCase().split(' ');
  
    // Capitaliza a primeira letra de cada palavra
    const formattedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  
    // Junta as palavras formatadas de volta em uma única string
    return formattedWords.join(' ');
  }


export default function Atendente({atendente}:props) {

    const [status, setStatus] = useState<BadgeStatusValue>('Offline');

    const [primeiroAcolhimento, setPrimeiroAcolhimento] = useState(true);

    const isDesktop = useResponsive('up', 'lg');

    const [openDialog, setOpenDialog] = useState(false); // Estado para controlar a abertura do Dialog


    const { user } = useAuthContext();


    useEffect(  () => {
        setPrimeiroAcolhimento(user?.jaconversou === 'n')

        setStatus(atendente.StatusAtendimento);


    },[user,atendente]);


     // Função para abrir o Dialog
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // Função para fechar o Dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

    

    const navigate = useNavigate();
    function currencyFormat(num:any) {
        if(num == null){ return `R$0,00`;}
        num = parseFloat(num.replace(",", "."));

        return `R$${  num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
    }

    const irparapagamanto = () =>{
        console.log('sasasass');
        // criarevento('escolheu_atendente');
        navigate(PATH_DASHBOARD.conversar(atendente?.id));
    }

    const verPerfil = () =>{
        console.log('sasasass');
        navigate(PATH_DASHBOARD.verperfil(atendente?.id));
    }

   const chipPrimeiroAtendimentoGratis = (
    <Chip sx={{backgroundColor: '#d9eee0', color: '#38b27f', borderRadius: '8px', fontWeight: '700', mb: 1}} label="Converse gratuitamente" />
   )

   const NomeFormatado = formatarTextoCaptalizar(atendente?.nome);

   const quantidadeDeAssuntos = (atendente?.assuntos.length || 0) -3;


    

    return (

        
        <>

            <div className='cardAtendente'>
                <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                >



                    <Item className="ItemAtendentePerfil">

                        {/* FOTO DE PERFIL DO ATENDENTE */}
                        <div className="FotoeNomeAtendente">
                            <div>
                            <CustomAvatar
                                className="FotoPerfilAtendente"
                                src={atendente?.avatar}
                                alt={atendente?.nome}
                                name={atendente?.nome}
                                onClick={verPerfil}
                                sx={{
                                    cursor: 'pointer',
                                    width: 60,
                                    height: 60,
                                    border: (theme) => `4px solid ${getStatusColor(status, theme)}`, // Define a largura e cor da borda com base no status.
                                    borderRadius: '50%', // Para criar uma borda circular.
                                }}
                            />

                            {atendente?.StatusAtendimento === 'Disponivel' ? (
                                <Typography
                                variant="caption"
                                sx={{
                                    color: (theme) => `${getStatusColor(status, theme)}`
                                }}
                                display="block"
                                gutterBottom
                              >
                                Online
                              </Typography>
                            ) : (
                                <Typography
                                variant="caption"
                                sx={{
                                    color: (theme) => `${getStatusColor(status, theme)}`
                                }}
                                display="block"
                                gutterBottom
                              >
                                Offline
                              </Typography>
                            )}



                            </div>

                            {/* NOME, ASSUNTOS E AVALIAÇÕES DO ATENDENTE */}
                            <div className="NomeETemasAtendente">
                                <Chip className={getCoresTemplate(atendente?.tipoAtedenteid).chipListaAtendentes} label={atendente?.tipoAtedente} size="small"/>



                                <Typography component='div' className="NomeDoAtendente">
                                    <Box sx={{ textTransform: 'capitalize', m: 1 }}>{NomeFormatado}</Box>

                                    



                                </Typography>
                                <div className="AssuntosAtendente">

                                    {atendente?.assuntos.map((assunto:any,index:any) => (

                                            <Typography className="Assunto" key={index}>  {assunto.NomeAssunto} </Typography>

                                    ))}
                                    <Chip className="MaisAssuntos" id="btnMaisAssuntos" label={`+ ${quantidadeDeAssuntos}`} onClick={handleOpenDialog} />

                                     {/* Dialog para exibir todos os assuntos */}
                                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                                        <DialogTitle>O atendente {atendente?.nome} conversa sobre estes assuntos:</DialogTitle>
                                        <DialogContent>
                                        {atendente?.assuntos.map((assunto: any, index: any) => (
                                            <Chip className="Assunto" sx={{mr: 1, mb: 0.5}} variant="outlined" color="primary" key={index} label={assunto.NomeAssunto} />
                                        ))}
                                        </DialogContent>
                                        <DialogActions>
                                        <Button onClick={handleCloseDialog} color="primary" variant="contained">
                                            Fechar
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                {/* AVALIAÇÕES DO ATENDENTE */}
                                <div className="AvaliacaoAtendimento">
                                    <Rating  className="AvaliacaoAtendente" name="read-only" value={atendente?.estrelas} readOnly />
                                    <Typography className="AvaliacaoAtendenteTexto">{atendente?.avaliacoes} avaliações</Typography>
                                </div>
                            </div>
                        </div>
                        <div className="ValorETempoAtendimento">


                       



                            <Typography className="ValorAtendimento"> {atendente?.tipoAtedenteid === 1 && primeiroAcolhimento || atendente?.ValorAtendimento === "0.00" ? ( chipPrimeiroAtendimentoGratis ) : (currencyFormat(atendente?.ValorAtendimento))}</Typography>
                            
                            <Typography className="TempoAtendimento">{atendente?.TempoAtendimento} min</Typography>
                        </div>
                    </Item>

                   
                </Stack>

                {/* BOTÕES DE AÇÃO */}
                <Box sx={{ width: 1 }}>
                    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2} className="BotoesAcao">
                        <Box gridColumn="span 7">
                            <Item>
                                {atendente?.tipoAtedenteid === 1 && primeiroAcolhimento ? (
                                    <Button sx={{p: 1}} fullWidth  variant="contained" color="success" onClick={irparapagamanto} > Conversar grátis</Button>
                                ) : (
                                    <Button sx={{p: 1}} fullWidth  variant="contained" className={getCoresTemplate(atendente?.tipoAtedenteid).chipListaAtendentes} color="primary" onClick={irparapagamanto} > Conversar</Button>
                                )}
                                
                            </Item>
                        </Box>
                        <Box gridColumn="span 5">
                            <Item>
                                <Button sx={{p: 1}} fullWidth variant="outlined" color="primary" className={getCoresTemplate(atendente?.tipoAtedenteid).chipListaAtendentescolor}  onClick={verPerfil}>Ver perfil</Button>
                            </Item>
                        </Box>
                    </Box>
                </Box>
            </div>
        </>
    );
}
