// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);


const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  chat: icon('ic_chat'),
  dashboard: icon('ic_dashboard'),
  atendentes: icon('ic_atendentes'),
  psi: icon('psi'),
  tera: icon('tera'),
  agendamentos: icon('ic_calendar'),
  financeiro: icon('ic_banking'),
  avaliacao: icon('ic_label'),
  quiz: icon('ic_external'),
  comunity: icon('ic_comunity')
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Eyhe 5.0',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.dashfirst, icon: ICONS.dashboard },
      { title: 'Atendimentos', path: PATH_DASHBOARD.atendimentos, icon: ICONS.chat },
      { title: 'Agendamentos', path: PATH_DASHBOARD.agendamentos, icon: ICONS.agendamentos  }, 
      { title: 'Nossos Anjos', path: PATH_DASHBOARD.atendentes, icon: ICONS.atendentes },
      { title: 'Nossos Psicólogos', path: PATH_DASHBOARD.psicologos, icon: ICONS.psi },
      { title: 'Nossos Terapeutas', path: PATH_DASHBOARD.terapeutas, icon: ICONS.tera },
      { title: 'Financeiro', path: PATH_DASHBOARD.financeiro, icon: ICONS.financeiro },
      { title: 'Avaliações', path: PATH_DASHBOARD.avaliacoes, icon: ICONS.avaliacao },
      { title: 'Pacotes', path: PATH_DASHBOARD.pacotes, icon: ICONS.ecommerce },
      { title: 'Quiz', path: PATH_DASHBOARD.quiz, icon: ICONS.quiz },
      { title: 'Comunidades', path: PATH_DASHBOARD.comunidades, icon: ICONS.comunity },
    ],
  },


];

export default navConfig;
