// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  confimarcadastro: '/confirmarcadastro',
  esquecisenha: '/esquecisenha',
  cadastro: '/cadastro',
  cadastrors: '/cadastrors',
  cadastroform: '/confirmarcadastroform',
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};




export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dashfirst: path(ROOTS_DASHBOARD, '/dashfirst'),
  atendimentos: path(ROOTS_DASHBOARD, '/atendimentos'),
  agendamentos: path(ROOTS_DASHBOARD, '/agendamentos'),
  atendentes: path(ROOTS_DASHBOARD, '/anjos'),
  psicologos: path(ROOTS_DASHBOARD, '/psicologos'),
  terapeutas: path(ROOTS_DASHBOARD, '/terapeutas'),
  avaliacoes: path(ROOTS_DASHBOARD, '/avaliacoes'),
  financeiro: path(ROOTS_DASHBOARD, '/financeiro'),
  verperfil: (id: string) => path(ROOTS_DASHBOARD, `/verperfil/${id}`),

  editarperfil: path(ROOTS_DASHBOARD, '/editarperfil'),
  pacotes: path(ROOTS_DASHBOARD, '/pacotes'),
  detalhespacotes: path(ROOTS_DASHBOARD, '/detalhespacotes'),
  personalizarperfil: path(ROOTS_DASHBOARD, '/personalizarperfil'),
  quiz: path(ROOTS_DASHBOARD, '/quiz'),
  comunidades: path(ROOTS_DASHBOARD, '/comunidades'),
  chamadaaudio: (id: string) => path(ROOTS_DASHBOARD, `/chamadaaudio/${id}`),
  chamadavideo:(id: string) =>  path(ROOTS_DASHBOARD, `/chamadavideo/${id}`),
  agendamento: (id: string) => path(ROOTS_DASHBOARD, `/agendamento/${id}`),
  reagendamento: (idAtendente: any,idAgendamento: any) => path(ROOTS_DASHBOARD, `/reagendamento/${idAtendente}/${idAgendamento}`),
  conversar: (id: string) => path(ROOTS_DASHBOARD, `/conversar/${id}`),
  salaespera:  (name: string) =>  path(ROOTS_DASHBOARD, `/salaespera/${name}`),
  pagamento: (id: string) => path(ROOTS_DASHBOARD, `/pagamento/${id}`),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    four: path(ROOTS_DASHBOARD, '/user/four'),
    five: path(ROOTS_DASHBOARD, '/user/five'),
    six: path(ROOTS_DASHBOARD, '/user/six'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
    video: (name: string) => path(ROOTS_DASHBOARD, `/video/${name}`),
    audio: (name: string) => path(ROOTS_DASHBOARD, `/audio/${name}`),
  },
};
