import { Helmet } from 'react-helmet-async';
// sections
import { Chat } from '../sections/@dashboard/chat';
import {Atendetentes} from "../sections/@dashboard/Atendentes";



export default function TerapeutasPage() {
  return (
    <>
      <Helmet>
        <title>Lista de  Terapeutas - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Atendetentes tipo={4} tiposnome="Nossos Terapeutas" />
    </>
  );
}
