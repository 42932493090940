import {useEffect, useState, useCallback, useRef } from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';



import  "./ComponetesAtedentes/atendentes.css";

import {
    Box,
    Alert,
    AlertTitle,
    Grid,
    Stack,
    Drawer,
    IconButton,
    IconButtonProps,
    Typography,Paper,
    Tooltip,
    Divider,
    Button,
    CircularProgress,
    List,
    MenuItem, Select, Pagination
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';

import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import Iconify from '../../../components/iconify';

import Atendente from './ComponetesAtedentes/atendente';

import FiltrarAtendente from './ComponetesAtedentes/FiltrarAtendente';
import axios from "../../../utils/axios";
import ChatNavItem from "../chat/nav/ChatNavItem";
// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

type props = {
    tipo: any;
    tiposnome: any;
};

export default function Atendetentes( {tipo,tiposnome}: props) {
    const [Atendentes, setAtendentes] = useState<any>({data:[]});
    const [valueAssunto, setassunto] = useState<any>('');
    const [query, setquery] = useState<any>('');
    const [tipoprofissional, settipoprofissional] = useState<any>(tipo);
    const [genero, setgenero] = useState<any>('');
    const [idade, setidade] = useState<any>('');
    const [avaliacao, setavaliacao] = useState<any>('');
    const [preco, setpreco] = useState<any>('');
    const [loading, setloading] = useState<any>(true);
    const [vazio, setisvazio] = useState<any>(false);

    const [page, setpage] = useState<any>(1);

    const navigate = useNavigate();

    const [dadosDash, setDadosDash] = useState<any>({totalonline:0});

    const myRefcomponete= useRef(null);

    const ifvazio = useCallback(async () => {
        try {
            const response = await axios.post(`/areadoheroi/atendentes?page=${page}`, {
                valueAssunto:'',
                query:'',
                tipoprofissional:'',
                idade:'',
                genero:'',
                avaliacao:'',
                preco:'',
            });

            const {atendentes, dados} = response.data;
            setAtendentes(atendentes);
            setDadosDash(dados);
            setloading(false);

        } catch (error) {
            console.error(error);
        }
    }, [page]);
    
    
    
    let message;
    if (dadosDash.tipoprofissional === 'todos' && dadosDash.assunto === 'todos') {
      message = `Mostrando <b>${dadosDash.totalonline}</b> atendentes online nesse momento. Entre eles: Anjos, Psicólogos, Terapeutas e Coaches.`;
    } else if (dadosDash.tipoprofissional !== 'todos' && dadosDash.assunto === 'todos') {
      message = `Mostrando <b>${dadosDash.totalonline}</b> ${dadosDash.tipoprofissional}${dadosDash.totalonline !== 1 && dadosDash.tipoprofissional !== 'Anjo Eyhe' ? 's' : ''} online nesse momento.`;
    } else {
      message = `Mostrando <b>${dadosDash.totalonline}</b> ${dadosDash.tipoprofissional}${dadosDash.totalonline !== 1 ?('s') : ('')} online que conversam sobre ${dadosDash.assunto} nesse momento.`;
    }
    

    const getAtendentes = useCallback(async () => {
        try {
            const response = await axios.post(`/areadoheroi/atendentes?page=${page}`, {
                valueAssunto,
                query,
                tipoprofissional,
                idade,
                genero,
                avaliacao,
                preco,
            });

            const {atendentes, dados} = response.data;

            setAtendentes(atendentes);
            setDadosDash(dados);
            setloading(false);

            
            if(atendentes.data.length === 0){
                ifvazio();
                setisvazio(true);
            }else{
                setisvazio(false);
            }
            

        } catch (error) {
            console.error(error);
        }
    }, [page, valueAssunto, query, tipoprofissional, idade, genero, avaliacao, preco, ifvazio]);



    useEffect(() => {

    if(sessionStorage.getItem('assunto')){
        setassunto(sessionStorage.getItem('assunto'));
    }
    if(sessionStorage.getItem('query')){
        setquery(sessionStorage.getItem('query'));
    }
    // if(sessionStorage.getItem('tipoprofissional')){
    //     settipoprofissional(sessionStorage.getItem('tipoprofissional'));
    // }
    if(sessionStorage.getItem('idade')){
        setidade(sessionStorage.getItem('idade'));
    }
    if(sessionStorage.getItem('genero')){
        setgenero(sessionStorage.getItem('genero'));
    }
    if(sessionStorage.getItem('avaliacao')){
        setavaliacao(sessionStorage.getItem('avaliacao'));
    }
    if(sessionStorage.getItem('preco')){
        // @ts-ignore
        setpreco(JSON.parse(sessionStorage.getItem('preco')));
    }
        getAtendentes();

    }, [getAtendentes]);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        // @ts-ignore
        myRefcomponete.current.scrollIntoView();
        setpage(value);
    };



    return (
            <>
                
                <Box sx={{ p: 2.5 }}>
                    <Button variant="text" onClick={()=>{ navigate(PATH_DASHBOARD.dashfirst,{replace:true})}}> <Iconify icon="eva:arrow-ios-back-outline"/> Dashboard </Button>
                    
                    <div className="BreadCrumbsListagemMaterial">
                        <CustomBreadcrumbs
                        heading={tiposnome}
                        links={[
                            {
                            name: 'Dashboard',
                            href: PATH_DASHBOARD.root,
                            },
                            {
                            name: 'Atendentes',
                            href: PATH_DASHBOARD.atendentes,
                            }
                        ]}
                        />
                    </div>
                </Box>

                <FiltrarAtendente setpage={setpage} setassunto={setassunto} setquery={setquery} settipoprofissional={settipoprofissional} setpreco={setpreco} setavaliacao={setavaliacao}/>

                <Box sx={{ p: 2.5 }} ref={myRefcomponete} >

                <Grid className='CardTopoAtendentes' sx={{mt: 2}}>
                        <Grid item xs={12}>
                          <Item>
                            <Typography sx={{textAlign: 'left'}} variant='subtitle2' dangerouslySetInnerHTML={{ __html: message }} />
                          </Item>
                        </Grid>
                      </Grid>

                {loading === false &&  Atendentes.length !== 0 ?

                    (<>
                        {
                        vazio ?  (
                            <Alert>
                                <AlertTitle>Não há anjos/profissionais para este assunto ou busca.</AlertTitle>
                                Atendentes <strong>online agora!</strong>
                            </Alert>
                       
                            ):('')
                        }{  /* eslint-disable-next-line react/destructuring-assignment */
                Atendentes.data.map((Atendentevar:any,index:any) => (
                    <div className='ListadeAtendentesHeroi' key={index}>
                        <Atendente atendente={Atendentevar}  />
                    </div>
                )

                ) }</>):('')}
                </Box>

                {loading === true &&  Atendentes.length !== 0 ?
                    (
                    <div className="divprogress"><CircularProgress  className="progress" /></div>
                    ):('')}

                {loading === false &&  Atendentes.length === 0 ?
                    ( <div className='itensAtendentesDashdiv'> <p>Não há anjos/profissionais para este assunto.</p></div>):('')}


                <Stack className='paginationChatAtendimentoLista' spacing={2}>
                    <Typography variant='subtitle2'> Página {Atendentes.current_page} de {Atendentes.last_page} </Typography>
                    <Pagination count={Atendentes.last_page} variant="outlined" onChange={handleChange} shape="rounded" />
                </Stack>


            </>
    );
}
