import { Helmet } from 'react-helmet-async';
// sections
import { Chat } from '../sections/@dashboard/chat';
import {Atendetentes} from "../sections/@dashboard/Atendentes";



export default function PsicologosPage() {
  return (
    <>
      <Helmet>
        <title>Lista de  Psicólogos  - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Atendetentes  tipo={2} tiposnome="Nossos Psicólogos" />
    </>
  );
}
